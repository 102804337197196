import React from "react";
import ReactTooltip from 'react-tooltip';
import './Rationale.scss';

function reasonsByType(reasons, reasonType)  {
  const specificReasons = reasons.filter(reason => reason[0] === reasonType);
  return specificReasons.map((reason, i) => (
    <li key={i}>
      <p>{reason[1]}</p>
      {reason.length > 2 &&
        <div className="tooltip"  data-tip={reason[2]}>
          i
        </div>
      }
    </li>
  ));
}


export function Rationale(props) {
  return (
    <div id="Rationale">
        <ReactTooltip 
          clickable={true} 
          place="right" 
          html={true} 
          event="click"
          className="tooltip-extra-class"
        />
      <div className="section first">
        <p className="section-header">BP Measurements</p>
        <div className="reasons">
          <ul>
            {reasonsByType(props.reasons, "BP Measurements")}
          </ul>
        </div>
      </div>
      <div className="section">
        <p className="section-header">Relevant Medical History</p>
        <div className="reasons">
          <ul>
            {reasonsByType(props.reasons, "Relevant Medical History")}
          </ul>
        </div>
      </div>
      <div className="section">
        <p className="section-header">Anti-hypertensive Medications</p>
        <div className="reasons">
          <ul>
            {reasonsByType(props.reasons, "Anti-hypertensive Medications")}
          </ul>
        </div>
      </div>
      <div className="section">
        <p className="section-header">Patient Information</p>
        <div className="reasons">
          <ul>
            {reasonsByType(props.reasons, "Patient Information")}
          </ul>
        </div>
      </div>
    </div>
  );
}