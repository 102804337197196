import React from 'react';
import './Display.scss';
import FhirClientProvider from "./FhirClientProvider";
import Patient from './Patient';


function Display(props) {
  return (
    <FhirClientProvider>
      <Patient patientId={props.patientId} />
    </FhirClientProvider>
  );
}

export default Display;
