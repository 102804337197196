import React from "react";
import './SubmitFeedback.scss';

export function SubmitFeedback({feedbackText, setFeedbackText, handleSubmitFeedback, handleCancelFeedback}) {

  return (
    <div id="SubmitFeedback">
      <label>Please enter your feedback below</label>
      <textarea type="text" rows={10} value={feedbackText || ""} onChange={setFeedbackText} />
      <div className="buttons">
        <button className="submit" onClick={handleSubmitFeedback}>Submit</button>
        <button className="cancel" onClick={handleCancelFeedback}>Cancel</button>
      </div>
    </div>
  );
}