import React from 'react';

const divStyle = {
  color: 'blue',
  textAlign: 'center',
  fontWeight: 'bold',
  padding: '30px',
  fontSize: '24px'
};

function Status(props) {
  return (
    <h1 style={divStyle}>
      EngageRx is alive
    </h1>
  );
}

export default Status;
