import React, { useState } from "react";
import './Checkbox.scss';

function handleClick(checkedValue, localHandler, externalHandler) {
  localHandler(checkedValue);
  if (externalHandler) {
    externalHandler();
  }
}

export function Checkbox(props) {
  const [checked, setChecked] = useState(false);

  return (
    <div id="Checkbox">
      <label className={`cbox-container ${props.borderStyle}`}>
        <span className={`text ${props.cstyle}`}>{props.label}</span>
        <input type="checkbox" 
               name={props.name} 
               checked={checked} 
               value={checked} 
               onChange={() => handleClick(!checked, setChecked, props.handler)} />
        <span className="checkmark"></span>
      </label>
    </div>
  );
}